import React from 'react'

const BannerLanding = (props) => (
 <section id="banner" className="style2">
    <div className="inner">
            <section>
    <div className="grid-wrapper">
                        <div className="col-8">
 <header className="major">
                            <h1>Mail a free limited edition print and promote an emerging artist.</h1></header>
                        </div>
                        <div className="col-4">
              <div className="content">
                            <p>Write your message in the form below, and we’ll mail the physical postcard to your destination via regular mail, for free.</p>
                        </div></div>
    </div>
    </section>
  </div>
    </section>


)

export default BannerLanding
