import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout2'
import BannerLanding from '../components/BannerLanding'

import pic01 from '../assets/images/pic01.webp'
import signature from '../assets/images/signature.webp'
const Landing = (props) => (


    <Layout>
        <Helmet>
            <title>INKIII</title>
            <meta name="" content="Terms and Conditions" />
        </Helmet>
    <div>
    <section>
    <br/>
                <h1>Privacy Policy</h1>
                <p>
INKIII is personally owned and funded. This privacy policy will explain how we use the personal data we collect from you when you use our website.
<br/>
INKIII only collects names, mailing addresses, and e-mail addresses for the purposes of sending a postcard with a customized message to the indicated address. 
<br/>
Users directly provide INKIII with their personal data. We do not store personal data after the postcard is sent.
<br/>
INKIII reserves the right to store anonomized messages themselves for non-commercial use.  
 <br/>
INKIII will not send you information about products and services. 
<br/>
INKIII does not store or sell any personal data. Your personal data will be deleted after your postcard is mailed. 
<br/>
INKIII does not use cookies or trackers. 
<br/>
Under the European GDPR, all users are entitled to the following:
<br/>
The right to access – You have the right to request for copies of your personal data. 
<br/>
The right to rectification – You have the right to corrections for any information you believe is inaccurate or incomplete. 
<br/>
The right to erasure – You have the right to request that  erasure of your personal data.
<br/>
The right to restrict processing – You have the right to restrict the processing of your personal data. 
<br/>
The right to object to processing – You have the right to object to processing of your personal data. 
<br/>
The right to data portability – You have the right to request that transfer of your data to another organization, or directly to you.
<br/>
The aforementioned requests must be replied to within one month. 
<br/>
If you have any questions about our privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. INKIII does not retain any of your personal data after INKIII postcards are mailed. 
<br/>
Email us at: hello [at] inkiii.com 
<br/>
INKIII keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 31 August 2021.



</p>
</section>

            </div>
   </Layout>
)


export default Landing
